import { render, staticRenderFns } from "./video-marquee.vue?vue&type=template&id=63c2a22a&scoped=true&lang=pug&"
import script from "./video-marquee.vue?vue&type=script&lang=coffee&"
export * from "./video-marquee.vue?vue&type=script&lang=coffee&"
import style0 from "./video-marquee.vue?vue&type=style&index=0&id=63c2a22a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c2a22a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveCraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default,Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default})
