






























import HeaderDropdown from '../../header-dropdown'
import DropdownMenu from './dropdown/dropdown-menu'
import ClickOutside from 'vue-click-outside'
import trapFocusMixin from 'library/mixins/trap-focus-mixin'

export default

	components: { HeaderDropdown, DropdownMenu }
	directives: { ClickOutside }
	mixins: [ trapFocusMixin ]

	props:
		item: Object
		active: Boolean

	data: ->
		dropdownOpen: false

	mounted: ->
		document.addEventListener 'scroll', @onScroll

	destroyed: ->
		document.removeEventListener 'scroll', @onScroll

	computed:

		hasDropdown: ->
			if @item?.items?.length > 0 then return true
			else return false

		classes: -> 'active' if @active

	methods:

		onClick: ->
			if @dropdownOpen then @hideDropdown()
			else
				@dropdownOpen = true
				@$store.commit('header/setMenuOpen', true)
				@$nextTick =>
					@setTrap @$el

		hideDropdown: ->
			@releaseAllTraps()
			if @dropdownOpen then @$store.commit('header/setMenuOpen', false)
			@dropdownOpen = false

		onScroll: -> @hideDropdown() if @dropdownOpen

		# when the contents of the menu columns are
		# updated, then re-initialize the set trap
		# this will release traps, and call it again
		# on this dropdown
		resetTrap: ->
			@$nextTick =>
					@setTrap @$el

	watch:
		$route: -> @hideDropdown()

